import {AppBar, IconButton, Toolbar, Typography} from "@mui/material";
import SvgIconTelegram from "../util/SvgIconTelegram";
import SvgIconVK from "../util/SvgIconVK";
import SvgIconYouTube from "../util/SvgIconYouTube";
import SvgIconTikTok from "../util/SvgIconTikTok";
import React from "react";

function AppBarBottom() {
    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Typography flexGrow={1}>© 2024</Typography>
                    <IconButton
                        href="https://t.me/isaev_inc"
                        target="_blank"
                        color="primary"
                    >
                        <SvgIconTelegram/>
                    </IconButton>
                    <IconButton
                        href="https://vk.com/isaev_inc"
                        target="_blank"
                        color="info"
                    >
                        <SvgIconVK/>
                    </IconButton>
                    <IconButton
                        href="https://youtube.com/@isaev_inc"
                        target="_blank"
                        color="error"
                    >
                        <SvgIconYouTube/>
                    </IconButton>
                    <IconButton
                        href="https://tiktok.com/@isaev_inc"
                        target="_blank"
                    >
                        <SvgIconTikTok/>
                    </IconButton>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default AppBarBottom
