import {Typography} from "@mui/material";
import React from "react";

function AppSlogan() {
    return (
        <Typography variant="overline" align="center" display="block" sx={{mb: 4}}>
            high tech , low life
        </Typography>
    )
}

export default AppSlogan
