import {Avatar, Card, CardActionArea, CardHeader, Divider, Grid, Typography, useTheme} from "@mui/material";
import SvgIconSteam from "../util/SvgIconSteam";
import SvgIconRuStore from "../util/SvgIconRuStore";
import SvgIconGooglePlay from "../util/SvgIconGooglePlay";
import SvgIconXiaomiGetApps from "../util/SvgIconXiaomiGetApps";
import SvgIconYandexMusic from "../util/SvgIconYandexMusic";
import SvgIconVKMusic from "../util/SvgIconVKMusic";
import SvgIconZvuk from "../util/SvgIconZvuk";
import SvgIconMTSMusic from "../util/SvgIconMTSMusic";
import SvgIconSpotify from "../util/SvgIconSpotify";
import SvgIconAppleMusic from "../util/SvgIconAppleMusic";
import SvgIconSFEROOM from "../util/SvgIconSFEROOM";
import SvgIconTaplink from "../util/SvgIconTaplink";
import React from "react";

function AppOther() {
    return (
        <>
            <Typography variant="h2" align="center">Разное</Typography>
            <Typography align="center" mb={4}>Официальные страницы в интернете</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://store.steampowered.com/developer/isaev-space"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconSteam/>
                                    </Avatar>
                                }
                                title="Steam"
                                subheader="ISAEV SPACE"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://www.rustore.ru/catalog/developer/b6b9d111"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconRuStore/>
                                    </Avatar>
                                }
                                title="RuStore"
                                subheader="ISAEV SPACE"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://play.google.com/store/apps/dev?id=6648438553280805647"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconGooglePlay/>
                                    </Avatar>
                                }
                                title="Google Play"
                                subheader="ISAEV SPACE"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://global.app.mi.com/developer?id=1083266"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconXiaomiGetApps/>
                                    </Avatar>
                                }
                                title="Xiaomi GetApps"
                                subheader="ISAEV"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://music.yandex.ru/artist/22224668"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconYandexMusic/>
                                    </Avatar>
                                }
                                title="Яндекс Музыка"
                                subheader="ISAEV SPACE"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://vk.com/artist/isaevspace"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconVKMusic/>
                                    </Avatar>
                                }
                                title="VK Музыка"
                                subheader="ISAEV SPACE"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://zvuk.com/artist/212921545"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconZvuk/>
                                    </Avatar>
                                }
                                title="Звук"
                                subheader="ISAEV SPACE"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://music.mts.ru/artist/22224668"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconMTSMusic/>
                                    </Avatar>
                                }
                                title="МТС Музыка"
                                subheader="ISAEV SPACE"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://open.spotify.com/artist/1l5mHN7QScSLqH2gCDmc3K"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconSpotify/>
                                    </Avatar>
                                }
                                title="Spotify"
                                subheader="ISAEV SPACE"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://music.apple.com/us/artist/isaev-space/1746917536"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconAppleMusic/>
                                    </Avatar>
                                }
                                title="Apple Music"
                                subheader="ISAEV SPACE"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://lk.sferoom.space/artists/70042"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconSFEROOM/>
                                    </Avatar>
                                }
                                title="SFEROOM"
                                subheader="ISAEV SPACE"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                {/*<Grid item xs={12} sm={6} md={3}>*/}
                {/*    <Card>*/}
                {/*        <CardActionArea*/}
                {/*            href="https://band.link/scanner?search=22224668&type=artist_id&service=yandex_music"*/}
                {/*            target="_blank"*/}
                {/*        >*/}
                {/*            <CardHeader*/}
                {/*                avatar={*/}
                {/*                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>*/}
                {/*                        <SvgIconBandLink/>*/}
                {/*                    </Avatar>*/}
                {/*                }*/}
                {/*                title="BandLink Сканер"*/}
                {/*                subheader="ISAEV SPACE"*/}
                {/*            />*/}
                {/*        </CardActionArea>*/}
                {/*    </Card>*/}
                {/*</Grid>*/}
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://taplink.cc/isaevspace"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconTaplink/>
                                    </Avatar>
                                }
                                title="Taplink"
                                subheader="@isaevspace"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            <Divider sx={{my: 4}}/>
        </>
    )
}

export default AppOther
