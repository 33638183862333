import {Avatar, Card, CardActionArea, CardHeader, Divider, Grid, Typography, useTheme} from "@mui/material";
import SvgIconBoosty from "../util/SvgIconBoosty";
import SvgIconVKDonut from "../util/SvgIconVKDonut";
import SvgIconYandexTips from "../util/SvgIconYandexTips";
import React from "react";

function AppDonate() {
    return (
        <>
            <Typography variant="h2" align="center">Поддержать</Typography>
            <Typography align="center" mb={4}>Разработка видеоигр</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Card>
                        <CardActionArea
                            href="https://boosty.to/nanaki"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconBoosty/>
                                    </Avatar>
                                }
                                title="Boosty"
                                subheader="@nanaki"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card>
                        <CardActionArea
                            href="https://vk.com/donut/isaev_inc"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconVKDonut/>
                                    </Avatar>
                                }
                                title="VK Donut"
                                subheader="@isaev_inc"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card>
                        <CardActionArea
                            href="https://tips.yandex.ru/guest/payment/7856410"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconYandexTips/>
                                    </Avatar>
                                }
                                title="Яндекс Чаевые"
                                subheader="payment/7856410"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            <Divider sx={{my: 4}}/>
        </>
    )
}

export default AppDonate
