import React, {FC} from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

const SvgIconVKDonut: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 36 36">
            <g clipPath="url(#avkd)">
                <path
                    fill="#F9D08C"
                    fillRule="evenodd"
                    d="M18 35.102c9.445 0 17.102-7.657 17.102-17.102S27.445.898 18 .898.898 8.555.898 18 8.555 35.102 18 35.102"
                    clipRule="evenodd"
                />
                <path
                    fill="#F9D08C"
                    fillRule="evenodd"
                    d="M18 35.102c9.445 0 17.102-7.657 17.102-17.102S27.445.898 18 .898.898 8.555.898 18 8.555 35.102 18 35.102"
                    clipRule="evenodd"
                />
                <path
                    fill="#CF8B19"
                    fillRule="evenodd"
                    d="M36 18c0 9.941-8.059 18-18 18S0 27.94 0 18 8.059 0 18 0s18 8.059 18 18m-.898 0c0 9.445-7.657 17.102-17.102 17.102S.898 27.445.898 18 8.555.898 18 .898 35.102 8.555 35.102 18"
                    clipRule="evenodd"
                />
                <path
                    fill="#B91C77"
                    fillRule="evenodd"
                    d="M15.48 9.725c.85-2.36 4.189-2.36 5.04 0l.927 2.57a1.81 1.81 0 0 0 1.648 1.197l2.73.087c2.508.08 3.54 3.255 1.558 4.794l-2.158 1.676a1.81 1.81 0 0 0-.63 1.936l.762 2.625c.698 2.41-2.002 4.372-4.078 2.963l-2.26-1.535a1.81 1.81 0 0 0-2.037 0l-2.26 1.535c-2.077 1.409-4.778-.553-4.079-2.963l.761-2.625a1.81 1.81 0 0 0-.63-1.936l-2.157-1.676c-1.982-1.539-.95-4.714 1.557-4.794l2.731-.087a1.81 1.81 0 0 0 1.648-1.197zm4.225.293c-.576-1.597-2.834-1.597-3.41 0l-.927 2.57a2.68 2.68 0 0 1-2.435 1.77l-2.731.087c-1.697.054-2.395 2.203-1.054 3.244l2.158 1.676a2.68 2.68 0 0 1 .93 2.862l-.76 2.624c-.474 1.63 1.354 2.958 2.758 2.005l2.261-1.535a2.68 2.68 0 0 1 3.01 0l2.26 1.535c1.405.953 3.232-.374 2.76-2.005l-.761-2.624a2.68 2.68 0 0 1 .93-2.862l2.158-1.676c1.34-1.041.643-3.19-1.054-3.244l-2.731-.087a2.68 2.68 0 0 1-2.435-1.77z"
                    clipRule="evenodd"
                />
                <path
                    fill="#EC64B3"
                    d="M19.705 10.019c-.576-1.597-2.834-1.597-3.41 0l-.927 2.57a2.68 2.68 0 0 1-2.435 1.77l-2.73.086c-1.698.055-2.396 2.203-1.055 3.244l2.158 1.676a2.68 2.68 0 0 1 .93 2.862l-.76 2.624c-.474 1.631 1.354 2.959 2.759 2.005l2.26-1.535a2.68 2.68 0 0 1 3.01 0l2.26 1.535c1.405.954 3.233-.374 2.76-2.005l-.761-2.624a2.68 2.68 0 0 1 .93-2.862l2.158-1.676c1.34-1.041.643-3.19-1.054-3.244l-2.731-.087a2.68 2.68 0 0 1-2.435-1.769z"
                />
                <path
                    fill="#B91C77"
                    fillRule="evenodd"
                    d="M12.25 16.038a.706.706 0 0 1 .969.243l.585.977a.706.706 0 0 1-1.212.725l-.584-.977a.706.706 0 0 1 .243-.968Zm8.645 4.913a.706.706 0 0 1-.927.37l-1.046-.448a.706.706 0 0 1 .556-1.297l1.047.448a.706.706 0 0 1 .37.927"
                    clipRule="evenodd"
                />
                <path
                    fill="#FFFEFC"
                    fillRule="evenodd"
                    d="M17.121 16.76c.351.17.498.592.328.943l-.495 1.025a.706.706 0 0 1-1.271-.615l.495-1.025a.706.706 0 0 1 .943-.328m6.216-1.062c.39-.01.715.297.725.686l.03 1.139a.706.706 0 1 1-1.41.038l-.031-1.139a.706.706 0 0 1 .686-.724"
                    clipRule="evenodd"
                />
                <path
                    fill="#A2EAFF"
                    fillRule="evenodd"
                    d="M19.788 14.372a.706.706 0 0 1-.926.373l-1.047-.446a.706.706 0 1 1 .553-1.3l1.047.447a.706.706 0 0 1 .373.926m-4.916 7.232a.706.706 0 0 1 .818.571l.2 1.121a.706.706 0 1 1-1.39.247l-.2-1.12a.706.706 0 0 1 .572-.82Zm8.179 1.539a.706.706 0 0 1-.227.972l-.967.601a.706.706 0 1 1-.745-1.199l.967-.6a.706.706 0 0 1 .972.226"
                    clipRule="evenodd"
                />
            </g>
            <defs>
                <clipPath id="avkd">
                    <path fill="#fff" d="M0 0h36v36H0z" />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
)

export default SvgIconVKDonut
