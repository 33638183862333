import React from 'react';
import {Container} from "@mui/material";
import AppBarTop from "./AppBarTop";
import AppBanner from "./AppBanner";
import AppGameVittaLucis from "./AppGameVittaLucis";
import AppGameIkeNorris from "./AppGameIkeNorris";
import AppSocial from "./AppSocial";
import AppContact from "./AppContact";
import AppOther from "./AppOther";
import AppDonate from "./AppDonate";
import AppInactive from "./AppInactive";
import AppSlogan from "./AppSlogan";
import AppBarBottom from "./AppBarBottom";

function App() {
    return (
        <>
            <AppBarTop/>
            <AppBanner/>
            <Container sx={{pt: 4}}>
                <AppGameVittaLucis/>
                <AppGameIkeNorris/>
                <AppSocial/>
                <AppContact/>
                <AppOther/>
                <AppDonate/>
                <AppInactive/>
                <AppSlogan/>
            </Container>
            <AppBarBottom/>
        </>
    )
}

export default App
