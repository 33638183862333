import BadgeH1 from "../util/BadgeH1";
import {deepPurple} from "@mui/material/colors";
import {Stack, Typography} from "@mui/material";
import React from "react";

function AppBanner() {
    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0}
            height={480}
            sx={{
                backgroundImage: 'url(bg.jpg)',
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }}
        >
            <BadgeH1 badgeContent="space" sx={{color: deepPurple[200]}}>
                <Typography variant="h1" color="#fff">ISAEV</Typography>
            </BadgeH1>
            <Typography variant="subtitle1" align="center" maxWidth={600}>
                Я очень сильно люблю видеоигры,
                рассказываю о своих проектах,
                делюсь своим опытом и процессом разработки.
            </Typography>
        </Stack>
    )
}

export default AppBanner
