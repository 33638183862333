import {AppBar, Avatar, Badge, Box, IconButton, Toolbar, Typography} from "@mui/material";
import {deepPurple} from "@mui/material/colors";
import SvgIconIsaevSpace from "../util/SvgIconIsaevSpace";
import SvgIconTelegram from "../util/SvgIconTelegram";
import SvgIconVK from "../util/SvgIconVK";
import SvgIconYouTube from "../util/SvgIconYouTube";
import SvgIconTikTok from "../util/SvgIconTikTok";
import React from "react";

function AppBarTop() {
    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton href="/" sx={{p: 0, mr: 2}}>
                    <Avatar sx={{bgcolor: deepPurple[500]}}>
                        <SvgIconIsaevSpace sx={{color: 'white'}}/>
                    </Avatar>
                </IconButton>
                <Box flexGrow={1}>
                    <Badge badgeContent="space" sx={{color: deepPurple[200]}}>
                        <Typography variant="h6" color="#fff">ISAEV</Typography>
                    </Badge>
                </Box>
                <IconButton
                    href="https://t.me/isaev_inc"
                    target="_blank"
                    color="primary"
                >
                    <SvgIconTelegram/>
                </IconButton>
                <IconButton
                    href="https://vk.com/isaev_inc"
                    target="_blank"
                    color="info"
                >
                    <SvgIconVK/>
                </IconButton>
                <IconButton
                    href="https://youtube.com/@isaev_inc"
                    target="_blank"
                    color="error"
                >
                    <SvgIconYouTube/>
                </IconButton>
                <IconButton
                    href="https://tiktok.com/@isaev_inc"
                    target="_blank"
                >
                    <SvgIconTikTok/>
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}

export default AppBarTop
