import {Avatar, Card, CardActionArea, CardHeader, Divider, Grid, Typography, useTheme} from "@mui/material";
import SvgIconIXBT from "../util/SvgIconIXBT";
import SvgIconStopGame from "../util/SvgIconStopGame";
import SvgIconTheDailyTelefrag from "../util/SvgIconTheDailyTelefrag";
import SvgIconTumblr from "../util/SvgIconTumblr";
import SvgIconRUTUBE from "../util/SvgIconRUTUBE";
import SvgIconDzen from "../util/SvgIconDzen";
import SvgIconNUUM from "../util/SvgIconNUUM";
import SvgIconDonationAlerts from "../util/SvgIconDonationAlerts";
import SvgIconMemeAlerts from "../util/SvgIconMemeAlerts";
import SvgIconSponsr from "../util/SvgIconSponsr";
import SvgIconBoomstarter from "../util/SvgIconBoomstarter";
import React from "react";

function AppInactive() {
    return (
        <>
            <Typography variant="h2" align="center">Неактивно</Typography>
            <Typography align="center" mb={4}>Тоже официальные страницы</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://www.ixbt.com/live/profile/350556/created/topics/"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconIXBT/>
                                    </Avatar>
                                }
                                title="iXBT"
                                subheader="@NanakiLucis"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://stopgame.ru/user/Nanaki/blogs"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconStopGame/>
                                    </Avatar>
                                }
                                title="StopGame"
                                subheader="@Nanaki"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://dailytelefrag.ru/user/75"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconTheDailyTelefrag/>
                                    </Avatar>
                                }
                                title="The Daily Telefrag"
                                subheader="user/75"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://www.tumblr.com/isaev-space"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconTumblr sx={{color: useTheme().palette.text.primary}}/>
                                    </Avatar>
                                }
                                title="Tumblr"
                                subheader="@isaev-space"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://rutube.ru/channel/38382219"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconRUTUBE/>
                                    </Avatar>
                                }
                                title="RUTUBE"
                                subheader="channel/38382219"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://dzen.ru/isaevspace"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconDzen/>
                                    </Avatar>
                                }
                                title="Дзен"
                                subheader="@isaevspace"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://nuum.ru/channel/isaev-space"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconNUUM/>
                                    </Avatar>
                                }
                                title="NUUM"
                                subheader="@isaev-space"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://plvideo.ru/@isaevspace"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{
                                        color: useTheme().palette.text.primary,
                                        bgcolor: useTheme().palette.background.default
                                    }}>П</Avatar>
                                }
                                title="Платформа"
                                subheader="@isaevspace"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://www.donationalerts.com/r/isaev_space"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconDonationAlerts/>
                                    </Avatar>
                                }
                                title="Donation Alerts"
                                subheader="@isaev_space"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://memealerts.com/isaev_space"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconMemeAlerts/>
                                    </Avatar>
                                }
                                title="Meme Alerts"
                                subheader="@isaev_space"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://sponsr.ru/isaevspace"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconSponsr/>
                                    </Avatar>
                                }
                                title="Sponsr"
                                subheader="@isaevspace"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://boomstarter.ru/profile/isaevspace"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconBoomstarter/>
                                    </Avatar>
                                }
                                title="Boomstarter"
                                subheader="@isaevspace"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            <Divider sx={{my: 4}}/>
        </>
    )
}

export default AppInactive
