import {Button, Card, CardActionArea, CardMedia, Divider, Grid, Typography} from "@mui/material";
import SvgIconVKPlay from "../util/SvgIconVKPlay";
import SvgIconRuStore from "../util/SvgIconRuStore";
import SvgIconGooglePlay from "../util/SvgIconGooglePlay";
import SvgIconXiaomiGetApps from "../util/SvgIconXiaomiGetApps";
import SvgIconHuaweiAppGallery from "../util/SvgIconHuaweiAppGallery";
import SvgIconBandLink from "../util/SvgIconBandLink";
import SvgIconZip from "../util/SvgIconZip";
import React from "react";

function AppGameIkeNorris() {
    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea
                            href="https://vk.com/video-218216146_456239058"
                            target="_blank"
                        >
                            <CardMedia
                                component="img"
                                image="ikenorris.jpg"
                                alt="Vitta Lucis"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h2" mb={4}>Ike Norris</Typography>
                    <Typography mb={4}>
                        Кто же это такой? Хороший мальчик и очень дружелюбный пёс, а также член моей семьи!
                        Как не сложно догадаться, сюжет игры будет связан именно с ним.
                        Всех карт раскрывать не буду, моя игра об этом расскажет!
                    </Typography>
                    <Typography>
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<SvgIconVKPlay/>}
                            href="https://vkplay.ru/play/game/ike_norris"
                            target="_blank"
                            sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                        >
                            VK Play
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<SvgIconRuStore/>}
                            href="https://apps.rustore.ru/app/com.isaev.ikenorris"
                            target="_blank"
                            sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                        >
                            RuStore
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<SvgIconGooglePlay/>}
                            href="https://play.google.com/store/apps/details?id=com.isaev.ikenorris"
                            target="_blank"
                            sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                        >
                            Google Play
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<SvgIconXiaomiGetApps/>}
                            href="https://global.app.mi.com/details?id=com.isaev.ikenorris"
                            target="_blank"
                            sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                        >
                            Xiaomi GetApps
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<SvgIconHuaweiAppGallery/>}
                            href="https://appgallery.huawei.com/app/C108095783"
                            target="_blank"
                            sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                        >
                            Huawei AppGallery
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<SvgIconBandLink/>}
                            href="https://band.link/isaevspaceikenorris"
                            target="_blank"
                            sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                        >
                            Саундтрек
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<SvgIconZip/>}
                            href="ISAEV SPACE - Ike Norris - Press-kit.zip"
                            target="_blank"
                            sx={{mr: 1, mb: 1, textTransform: 'none', color: '#fff'}}
                        >
                            Пресс-кит
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{my: 4}}/>
        </>
    )
}

export default AppGameIkeNorris
