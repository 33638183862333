import {Avatar, Card, CardActionArea, CardHeader, Divider, Grid, Typography, useTheme} from "@mui/material";
import SvgIconTelegram from "../util/SvgIconTelegram";
import SvgIconVK from "../util/SvgIconVK";
import React from "react";
import SvgIconMailGoogle from "../util/SvgIconMailGoogle";
import SvgIconMailYandex from "../util/SvgIconMailYandex";

function AppContact() {
    return (
        <>
            <Typography variant="h2" align="center">Контакты</Typography>
            <Typography align="center" mb={4}>Сергей Исаев</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://t.me/nanaki_lucis"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconTelegram/>
                                    </Avatar>
                                }
                                title="Telegram"
                                subheader="@nanaki_lucis"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="https://vk.com/nanaki"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconVK/>
                                    </Avatar>
                                }
                                title="VK"
                                subheader="@nanaki"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="mailto:nanaki.god@gmail.com"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconMailGoogle/>
                                    </Avatar>
                                }
                                title="Gmail"
                                subheader="nanaki.god@gmail.com"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea
                            href="mailto:nanaki.god@yandex.ru"
                            target="_blank"
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{bgcolor: useTheme().palette.background.default}}>
                                        <SvgIconMailYandex/>
                                    </Avatar>
                                }
                                title="Яндекс Почта"
                                subheader="nanaki.god@yandex.ru"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            <Divider sx={{my: 4}}/>
        </>
    )
}

export default AppContact
